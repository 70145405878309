<template>
    <v-row no-gutters>
        <v-row v-if="$route.path === '/users' && editedIndex === -1">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-checkbox
                    label="Criar a partir de usuário existente"
                    @change="setCopyUser($event)"
                    color="primary"
                    :ripple="false"
                    :value="copyUser"
                >
                </v-checkbox>
            </v-col>
        </v-row>

        <template v-if="copyUser">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-autocomplete
                    label="Usuário referência *"
                    v-model="copyUserPayload.selectedUser"
                    :items="users"
                    item-text="name"
                    item-value="id_user"
                    return-object
                    :loading="gettingUsers"
                />

                <v-text-field
                    type="text"
                    label="Nome do usuário *"
                    v-model="copyUserPayload.name"
                />

                <v-text-field
                    type="email"
                    label="E-mail *"
                    v-model="copyUserPayload.email"
                />
            </v-col>
        </template>
        
        <template v-else>
            <template v-for="(item, index) in editedItem" >
                <v-col data-testid="znap-dialog-input"
                    v-if="!item.hide" :key="item.name"
                    cols="12" sm="12" md="12" lg="12" xl="12"
                >
                    <!-- item type text not password -->
                    <v-text-field v-if="!item.options && (item.type === 'VARCHAR' || item.type === 'TEXT') && item.name !== 'password'"
                        v-model="item.value"
                        :label="isRequired(item) ? item.label + ' *' : item.label"
                        type="text"
                        :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                        :counter="item.length"
                        :maxlength="item.length"
                    />

                    <!-- item type text password -->
                    <v-text-field v-if="!item.options && (item.type === 'VARCHAR' || item.type === 'TEXT') && item.name === 'password'"
                        v-model="item.value"
                        :label="isRequired(item) ? item.label + ' *' : item.label"
                        :type="showPassword ? 'text' : 'password'" 
                        :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item) && validPassword(item)"
                        :counter="item.length"
                        :maxlength="item.length"
                        prepend-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPassword = !showPassword"
                    />

                    <!-- item columnType === check -->
                    <v-switch v-if="!item.options && item.type === 'INT' && item.columnType === 'check'"
                        :label="item.label"
                        v-model="item.value"
                        @change="setSwitchValue($event, item)"
                    />

                    <!-- item type === int -->
                    <v-text-field v-if="!item.options && item.type === 'INT' && item.columnType !== 'check'"
                        v-model.number="item.value"
                        :label="isRequired(item) ? item.label + ' *' : item.label"
                        type="number"
                        :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                        :counter="item.length"
                        :maxlength="item.length"
                    />

                    <!-- item type === decimal -->
                    <v-text-field v-if="!item.options && item.type === 'DECIMAL'"
                        v-model.number="item.value"
                        @change="formatFloat(item.value)"
                        :label="isRequired(item) ? item.label + ' *' : item.label"
                        type="number"
                        :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                        :counter="item.length"
                        :maxlength="item.length"
                    />

                    <!-- country code options -->
                    <v-autocomplete v-if="item.options && item.name.includes('country_code')"
                        :label="isRequired(item) ? item.label + ' *' : item.label"
                        v-model="item.value"
                        :items="item.options"
                        item-value="text"
                        :item-text="item => `${item.text} (${item.code})`"
                        return-object
                        @change="setSelectedOption(null, $event, item)"
                        :clearable="true"
                        :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                    />

                    <!-- item has options -->
                    <v-autocomplete v-if="item.options && !item.name.includes('country_code') && item.columnType !== 'radio'"
                        :label="isRequired(item) ? item.label + ' *' : item.label"
                        v-model="item.value"
                        :items="getOptions(item)"
                        item-value="text"
                        return-object
                        @change="setSelectedOption(item.columnId, $event, item)"
                        :clearable="isRequired(item) ? false : true"
                        :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                        :disabled="verifyOptionItemsLength(item)"
                    />
                    
                    <!-- item type === date or item type === datetime -->
                    <v-menu v-if="item.type === 'DATE' || item.type === 'DATETIME'"
                        :ref="'menu' + index"
                        v-model="datepicker[index]"
                        :close-on-content-click="false"
                        :return-value.sync="item.value"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="formatDate(item.value)"
                                :label="isRequired(item) ? item.label + ' *' : item.label"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :error="editedIndex < 0 ? false : isRequired(item) && requiredValidation(item)"
                                :clearable="!isRequired(item)"
                                @click:clear="clearDatepicker(item.name, $event)"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="item.value"
                            no-title
                            scrollable
                            color="primary"
                        >
                            <v-spacer></v-spacer>

                            <!-- <v-btn text color="primary" @click="datepicker[index] = false">
                                Cancelar
                            </v-btn> -->
                            
                            <v-btn text color="primary" @click="saveSelectedDate(item.value, index)">
                                Alterar
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </template>
        </template>
    </v-row>
</template>

<script>
export default {
    name: 'ZnapDialog',

    props: {
        editedItem: { type: Array, required: true },
        editedIndex: { type: Number, required: true },
        disabled: { type: Boolean, required: true },
        options: { type: Array, required: true },
        copyUser: { type: Boolean, required: false }
    },

    data() {
        return {
            datepicker: [],
            date: '',
            selected: [],
            users: [],
            gettingUsers: false,
            copyUserPayload: {
                selectedUser: null,
                name: null,
                email: null
            },
            showPassword: false,
        }
    },

    watch: {
        copyUser(val) {
            if (val) {
                this.getUsers()
            }
        },

        copyUserPayload: {
            deep: true,
            handler(val) {
                this.$emit('setCopyUserPayload', val)
            }
        },

        editedIndex: {
            immediate: true,
            handler(val) {
                if (val > -1) {
                    this.options.forEach(o => {
                        if (o.filters) {
                            let item = this.editedItem.find(i => i.columnId === o.column)
                            if (item) {
                                let selection = o.items.find(i => i.text === item.value)
                                if (selection) this.populateFilterableFilter(o, selection)
                            }
                        }
                    })
                }
            }
        }
    },

    methods: {
        setCopyUser(e) {
            this.clearCopyUser()
            this.$emit('setCopyUser', e)
        },

        clearCopyUser() {
            this.copyUserPayload = {
                selectedUser: null,
                name: null,
                email: null
            }
        },

        async getUsers() {
            this.gettingUsers = true

            if (this.$route.path === '/users' && this.editedIndex === -1) {
                try {
                    const res = await this.$http.post(this.$ipUser + 'user/list', {})
                    if (res) {
                        this.users = res.data.rows
                        this.gettingUsers = false
                    }
                } catch (err) {
                    this.$fnError(err)
                    this.gettingUsers = false
                }
            }
        },

        getOptions(item) {
            let option = this.options.find(option => option.column === item.columnId)
            let items = option.items

            if (option.filteredBy) {
                items = option.filteredItems
            }

            return items
        },

        verifyOptionItemsLength(item) {
            let items = this.getOptions(item)
            return items.length ? false : true 
        },

        populateFilterableFilter(option, selection) {
            option.filters.forEach(async f => {
                let foundOption = this.options.find(o => o.column === f)

                let filter = {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: option.column,
                            operator: '=',
                            value: selection.id
                        }
                    ]
                }

                try {
                    let res = await this.$http.post(foundOption.endpoint[0] + foundOption.endpoint[1] + '/list-options', { filter })
                    if (res) {
                        foundOption.filteredItems = res.data.rows
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            })
        },

        setSelectedOption(columnId, e, item) {
            // verify if selected option filters another input field
            let found = this.options.find(o => o.column === columnId)
            if (found) {
                if (found.filters) {
                    found.filters.forEach(f => {
                        let foundOption = this.options.find(o => o.column === f)
                        let foundEditedItemColumn = this.editedItem.find(e => e.columnId === foundOption.column)
                        foundEditedItemColumn.value = null

                        this.populateFilterableFilter(found, e)
                    })
                }
            }

            if (item.name.includes('country_code')) {
                let index = this.editedItem.findIndex(i => i.name === item.name)
                let value = null

                if (e) {
                    value = e.text.slice(0, 2)
                }

                this.editedItem[index].value = value
            } else {
                this.editedItem.forEach((item, i) => {
                    if (e) {
                        if (item.name === columnId) this.editedItem[i].value = e.id
                    } else {
                        if (item.name === columnId) this.editedItem[i].value = null
                    }
                })
            }
        },

        clearDatepicker(itemName, e) {
            this.editedItem.forEach((item, i) => {
                if (e) {
                    if (item.name === itemName) this.editedItem[i].value = e.id
                } else {
                    if (item.name === itemName) this.editedItem[i].value = null
                }
            })
        },

        requiredValidation(item) {
            if (item && item.value !== null) {
                if ( item.value.length === 0 ) return true
            }
        },

        validPassword(item) {
            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
            if (item && item.value !== null) {
                if(strongRegex.test(item.value)) {
                    console.log('teste')
                    return false
                }
                else {
                    console.log('teste2')
                    return true
                }
            }
        },

        isRequired(item) {
            return item.rules.includes('required') ? true : false 
        },

        saveSelectedDate(date, index) {
            this.$refs['menu' + index][0].save(date)
            this.date = this.$options.filters.dateFilter(date)
        },

        formatDate(date) {
            if (date === undefined || date === null) {
                return ''
            } else {
                return this.$options.filters.dateFilter(date)
            }
        },

        formatFloat(float) {
            if (float === undefined || float === null) {
                return ''
            } else {
                return this.$options.filters.floatFilter(float)
            }
        },

        setSwitchValue(e, item) {
            if (e) {
                item.value = 1
            } else {
                item.value = 0
            }
        },

        setRadioValue(e, item) {
            let i = this.editedItem.findIndex(edited => edited.name === item.name)
            let selectedOption = item.options.find(o => o.text === e)
            this.editedItem[i].value = selectedOption.text
        }
    },
}
</script>

<style>

</style>